<template>
  <div :class="['top-live-drop-action-button', rootClasses]">
    <div class="top-live-drop-action-button__content">
      <SvgoFireIcon v-if="isTop" class="top-live-drop-action-button__content-icon" />
      <span class="top-live-drop-action-button__content-text">{{ text }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ETopLiveDropActionButtonVariants, type ITopLiveDropActionButtonProps } from './TopLiveDropActionButton.types';

const props = defineProps<ITopLiveDropActionButtonProps>();
const { isActive, variant } = toRefs(props);

const rootClasses = computed(() => ({
  'top-live-drop-action-button--active': isActive.value,
}));
const isTop = computed(() => variant.value === ETopLiveDropActionButtonVariants.TOP);
const text = computed(() => (isTop.value ? 'Top' : 'Live'));
</script>

<style scoped lang="scss" src="./TopLiveDropActionButton.scss" />
