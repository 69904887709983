<template>
  <SkeletonComponentLoader>
    <section v-if="!isLoaded" class="top-live-drop-controller-skeleton">
      <div class="top-live-drop-controller-skeleton__selector">
        <SkeletonBaseForeground :width="170" :height="68" :border-radius="8" />
      </div>
      <SkeletonBaseCupcake v-for="i in MAX_ELEMENTS" :key="i" />
    </section>
    <TopLiveDropController v-else />
  </SkeletonComponentLoader>
</template>

<script setup lang="ts">
import { MAX_ELEMENTS, useTopLiveDropStore } from '~/project-layers/dota/store/topLiveDrop/topLiveDrop.store';

const topLiveDropStore = useTopLiveDropStore();
const { isLoaded } = storeToRefs(topLiveDropStore);
</script>

<style lang="scss" scoped src="./TopLiveDropController.skeleton.scss" />
