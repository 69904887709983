export enum ETopLiveDropRarities {
  ARCANA = 'arcana',
  COMMON = 'common',
  IMMORTAL = 'immortal',
  LEGENDARY = 'legendary',
  MYTHICAL = 'mythical',
  RARE = 'rare',
  UNCOMMON = 'uncommon',
}

export interface ITopLiveDropWSItem {
  botid?: string;
  caseId: number;
  caseimg: string;
  casename: string;
  casetype: string;
  casevar: string;
  city?: string;
  datetime: string;
  err?: string;
  game: string;
  gamename: string;
  id: string | number;
  image: string;
  img: string;
  ip?: string;
  item: string;
  itemEn?: string;
  itemId: string;
  key?: string;
  link?: string;
  name: string;
  offerid?: string;
  partner?: string;
  pcid?: string;
  percent: string;
  price: string;
  quality: string;
  sellDt?: string;
  state?: string;
  status: string;
  steamlogin?: string;
  token?: string;
  transaction?: string;
  transactionDt?: string;
  transactionType?: string;
  type: string;
  user: string;
  userid: string;
  username: string;
  usertoken?: string;
}

export interface ITopLiveDropApiAdditional {
  percent: string;
}

export interface ITopLiveDropApiItem {
  image: string;
  imageHover: string;
  isUltraRare: boolean;
  percent: number;
  quality: string;
  type: string;
}

interface ITopLiveDropApiUser {
  id: number;
  name: string;
}

export interface ITopLiveDropApiData {
  additionalData: ITopLiveDropApiAdditional;
  itemData: ITopLiveDropApiItem;
  userData: ITopLiveDropApiUser;
}

export interface ITopLiveDropApiResponse {
  data: ITopLiveDropApiData[];
}

export interface ITopLiveDropImages {
  default: string;
  hover: string;
}

export interface ITopLiveDropUser {
  id: number;
  nickname: string;
}

export interface ITopLiveDropModificator {
  color: string;
  icon: string;
  text?: string;
}

export interface ITopLiveDropItem {
  id: string;
  images: ITopLiveDropImages;
  isUltraRare: boolean;
  modificator?: ITopLiveDropModificator;
  rarity: ETopLiveDropRarities;
  user: ITopLiveDropUser;
}
