<template>
  <section class="top-live-drop-controller">
    <div v-if="changeXL(true, false)" class="top-live-drop-controller__actions">
      <TopLiveDropActionButton
        :variant="ETopLiveDropActionButtonVariants.TOP"
        :is-active="!isActiveLive"
        @click="isActiveLive = false"
      />
      <TopLiveDropActionButton
        :variant="ETopLiveDropActionButtonVariants.LIVE"
        :is-active="isActiveLive"
        @click="isActiveLive = true"
      />
    </div>
    <div class="top-live-drop-controller__list">
      <TransitionGroup name="nested">
        <TopLiveDropItem v-for="item in activeList" :key="item.id" v-bind="item" />
      </TransitionGroup>
    </div>
  </section>
</template>

<script setup lang="ts">
import TopLiveDropItem from '~/project-layers/dota/components/TopLiveDropItem/TopLiveDropItem.vue';
import { ETopLiveDropActionButtonVariants } from '~/components/TopLiveDropActionButton/TopLiveDropActionButton.types';
import { useTopLiveDropStore } from '~/project-layers/dota/store/topLiveDrop/topLiveDrop.store';

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const topLiveDropStore = useTopLiveDropStore();
const { isActiveLive, activeList } = storeToRefs(topLiveDropStore);
</script>

<style scoped lang="scss" src="./TopLiveDropController.scss" />
