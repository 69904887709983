<template>
  <NuxtLinkLocale :to="userLink" target="_blank" :class="['top-live-drop-item', rootClasses]">
    <div v-if="modificator" class="top-live-drop-item__modificator">
      <div class="top-live-drop-item__modificator-content">
        <component :is="modificatorIcon" v-if="modificatorIcon" class="top-live-drop-item__modificator-content--icon" />
        <span v-if="modificator.text" class="top-live-drop-item__modificator-content--text">
          {{ modificator.text }}
        </span>
      </div>
    </div>
    <NuxtImg
      v-if="images.default"
      class="top-live-drop-item__image"
      :src="images.default"
      loading="lazy"
      provider="localProvider"
    />
    <div class="top-live-drop-item__line"></div>
    <div v-if="changeXL(true, false)" class="top-live-drop-item__hover">
      <NuxtImg
        v-if="images.hover"
        class="top-live-drop-item__hover-image"
        :src="images.hover"
        loading="lazy"
        provider="localProvider"
      />
      <div :class="['top-live-drop-item__hover-nickname', rootClasses]">{{ user.nickname }}</div>
    </div>
  </NuxtLinkLocale>
</template>

<script setup lang="ts">
import type { TTopLiveDropItemProps } from './TopLiveDropItem.types';

const props = defineProps<TTopLiveDropItemProps>();
const { user, modificator, rarity, isUltraRare } = toRefs(props);

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const rootClasses = computed(() => ({
  'top-live-drop-item--shadow': isUltraRare.value,
}));

const modificatorIcon = computed(() =>
  modificator?.value ? changeXL(modificator.value.icon, `${modificator.value.icon}-mobile`) : '',
);

const modificatorColor = computed(() => (modificator?.value ? modificator.value.color : ''));

const color = computed(() => (isUltraRare.value ? 'var(--rarity-ultra)' : `var(--rarity-${rarity.value})`));

const userLink = computed(() => `${useLinks().PROFILE}${user.value.id}`);
</script>

<style lang="scss" scoped>
.top-live-drop-item {
  --item-color: v-bind('color');
  --modificator-color: v-bind('modificatorColor');
}
</style>

<style lang="scss" scoped src="./TopLiveDropItem.scss" />
